import React from "react"
import { Link } from "gatsby"
import Head from "../components/head"
import Layout from "../components/layout"
import * as aboutStyles from "./about.module.scss"
import MeImg from "../assets/me.png"

const AboutPage = () => {
  return (
    <Layout>
      <Head title="About" />
      <h1>About</h1>
      <img src={MeImg} alt="Nichole Treadway" className={aboutStyles.img} />
      <p>
        I am a mobile engineer with over 10 years of professional experience.
        Currently, I'm an engineering manager at Tinder, working with an amazing
        team of iOS and Android engineers on the User Growth team, building
        features that help new Tinder users put their best foot forward. Before
        transitioning to management, I was an iOS engineer at Tinder, working on
        a variety of new features and experiences.
      </p>
      <p>
        Before Tinder, I worked at Goodreads (an Amazon subsidiary), leading the
        iOS team. As a life-long book lover, it was amazing to work on a product
        that combined my love of engineering and reading.
      </p>

      <p>
        Before Goodreads, I was a Ruby/Rails developer at LivingSocial, building
        internal tools and dashboards for the Data Engineering team.
      </p>

      <p>In 2010, I graduated from MIT with a degree in Computer Science.</p>

      <p>
        Recently I've started to dabble in front-end technologies again,
        learning React, Node, and Gatsby (what this site was built with).
      </p>

      <p>Originally from the East Coast, I currently live in the Bay Area.</p>

      <p>
        <Link to="/contact">Get in touch!</Link>
      </p>
    </Layout>
  )
}

export default AboutPage
